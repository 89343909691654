// extracted by mini-css-extract-plugin
export var buttonSection = "about-us-module--buttonSection--7ccTe";
export var content = "about-us-module--content--oMahJ";
export var emoji = "about-us-module--emoji--2p6Kg";
export var heading = "about-us-module--heading--co5y2";
export var hide = "about-us-module--hide--mrQm0";
export var icon = "about-us-module--icon--AragW";
export var intro = "about-us-module--intro--k7OvN";
export var introPhoto = "about-us-module--introPhoto--kVPNw";
export var lastPhoto = "about-us-module--lastPhoto--43COq";
export var quotation = "about-us-module--quotation--qV37r";
export var section = "about-us-module--section--BxJrs";
export var signature = "about-us-module--signature--IFPw8";
export var text = "about-us-module--text--5Rw42";
export var title = "about-us-module--title--V53nK";
export var visible = "about-us-module--visible--qbmFh";