import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";

import { row, reverse, contentBox, titleBox, imgBox, img } from "./about-section.module.scss";

if (typeof window !== "undefined") {
    gsap.registerPlugin(ScrollTrigger);
}

export interface ISectionRowProps {
    className?: string;
    isReversed?: boolean;
    mobileLayout?: 1 | 2 | 3;
    title?: string;
    content?: string;
    image?: IGatsbyImageData;
}

const AboutSection: React.FC<ISectionRowProps> = ({
    className = "",
    isReversed = false,
    title = "",
    content = "",
    image,
}) => {
    const imageRef = useRef(null);

    useEffect(() => {
        gsap.fromTo(
            imageRef.current,
            {
                opacity: 0.8,
                scale: 0.7,
            },
            {
                opacity: 1,
                scale: 1,
                duration: 1,
                ease: "linear",
                scrollTrigger: {
                    trigger: imageRef.current,
                    start: "top-=100% +=50%",
                    end: "center+=20% +=80%",
                    scrub: true,
                },
            }
        );
    }, []);

    return (
        <div
            className={`
                ${row}
                ${className}
                ${isReversed ? reverse : ""}
            `}
        >
            {title && <div className={titleBox}>{title}</div>}

            {content && (
                <div className={contentBox}>
                    <ReactMarkdown
                        // @ts-ignore
                        rehypePlugins={[rehypeRaw]}
                    >
                        {content}
                    </ReactMarkdown>
                </div>
            )}
            <div ref={imageRef} className={imgBox}>
                {image && <GatsbyImage alt="" image={image} className={img} />}
            </div>
        </div>
    );
};

export default AboutSection;
